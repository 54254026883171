import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";

interface OTPInputProps {
  length?: number;
  onComplete?: (otp: string) => void;
}

const OTP: React.FC<OTPInputProps> = ({ length = 6, onComplete }) => {
  const [otp, setOtp] = useState<string[]>(new Array(length).fill(""));
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(120);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  // time OTP
  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [timeRemaining]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleResendOtp = async () => {
    if (isResendDisabled) return;
    setIsResendDisabled(true);
    setTimeRemaining(120);
    try {
      await axios.post(
        `${process.env.React_App_DYO_CHEF_Login_With_otp}`,
        { phone, contryCode: "91" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const phone = location.state?.phone || "Unknown";
  const responseOTP = location.state?.responseOTP || null;

  console.log("Navigate State:", phone, responseOTP);

  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setError(null); // Clear previous error

    const value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    console.log("Current OTP:", newOtp.join(""));
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError(null);

    const pastedData = e.clipboardData
      .getData("text")
      .slice(0, length)
      .replace(/[^0-9]/g, "");
    if (pastedData.length === length) {
      setOtp(pastedData.split(""));
      inputRefs.current[length - 1]?.focus();
      if (onComplete) onComplete(pastedData);
    }
  };

  const handleValidateOTP = async () => {
    setError(null);
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.React_App_DYO_CHEF_OTP_validate}`,
        { phone, otp: otp.join("") },
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("API Response:", response.data);

      if (response.data?.data?.accessToken) {
        console.log("Access Token Received:", response.data.data.accessToken);
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("id", response?.data?.data?.id);
        localStorage.setItem(
          "refreshToken",
          response?.data?.data?.refreshToken
        );
        localStorage.setItem("role", response?.data?.data?.role);
        localStorage.setItem(
          "refreshTokenExpiration",
          response?.data?.data?.refreshTokenExpiration
        );
        localStorage.setItem("existing", response?.data?.data?.existing);
        navigate("/");
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (err: any) {
      console.error("Error validating OTP:", err);
      setError(
        err.response?.data?.message || "Failed to verify OTP. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <h2>
          Welcome to DYO <span>CHEF</span>
        </h2>
        <div
          className="login-form"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <strong className="otp-content">
            OTP has been sent to your mobile number {phone}.
          </strong>
          <label className="otp-content">
            Please enter the 6-digit code below to proceed.
          </label>
          {/* {timeRemaining > 0 ? ( */}
          <h6>
            Time Remaining:{" "}
            <span style={{ color: "#d02429" }}>
              {formatTime(timeRemaining)}
            </span>
          </h6>
          {/* ) : ( */}
          <h6>
            Didn't receive the code?
            <button
              className="btn"
              style={{ color: "#d02429" }}
              onClick={handleResendOtp}
            >
              Resend OTP
            </button>
          </h6>
          {/* )}  */}
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "center",
              margin: "15px 0",
            }}
          >
            {otp.map((value, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                value={value}
                maxLength={1}
                onChange={(e) => handleChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={handlePaste}
                className={`otp-input ${error ? "error-border" : ""}`}
              />
            ))}
          </div>

          {error && <p className="error-text">{error}</p>}

          <button
            className="btn btn-rounded btn-width btn-dyo-primary"
            disabled={otp.join("").length !== length || loading}
            onClick={handleValidateOTP}
          >
            {loading ? "Verifying OTP..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OTP;
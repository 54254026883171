import { Link } from "react-router-dom"


const PaymentFailed = () => {
  return (
    <div className="payment-section">
            <div className="payment">
              <div className="text-end">
                <Link to="/">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                  ></button>
                </Link>
              </div>
              <div className="payment-gateway ">
                <div className="payment-gateway-inner text-center">
                  <img src="https://cdn-icons-png.flaticon.com/512/6659/6659895.png" alt="" className="payment-logo mt-3" />
                  <h1 className="success-message">Payment Failed</h1>
                  {/* <p>Booking ID:{bookingDetails.id}</p> */}
                </div>
              </div>
              <div className="text-center">
                <Link to='/bookingview'><button className="payment-button">View booking</button></Link>
              </div>
            </div>
          </div>
  )
}

export default PaymentFailed;

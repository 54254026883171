import "./coupon.css";

const Coupon = () => {
  return (
    <>
      {/* <div className="coupon-inner-iteam">
        <div className="marquee">
          <h6 id="coupon-code">100/- Off Use Coupon Code DYOCHEFGRAB100</h6>
        </div>
      </div> */}
    </>
  );
};

export default Coupon;

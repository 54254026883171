const apiResponse = async (status:any) => {
    if(status === 401){
    //    return localStorage.removeItem("accessToken")
       localStorage.removeItem("accessToken")
       localStorage.removeItem("user")
    }
    if(status === 404){
        return console.log("Page Not Found")
    }
}

export default apiResponse;
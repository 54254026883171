import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Bookingdetails from "../BookingDetails";
import Loader from "../../../components/Loader";
import { FaStar } from "react-icons/fa";
import "./bookingtable.css";
import apiResponse from "../../../utils/Response";

interface mybooking {
  status: number;
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  city: string;
  region: string;
  startDate: string;
  requirement: string;
}

const Mybookings = () => {
  const [userbookings, setUserbookings] = useState<mybooking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<mybooking[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [rating, setRating] = useState<string | null>(null);
  const [hover, setHover] = useState<string | null>(null);
  const [note, setNote] = useState<string>("");
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null);
  const [selectedChefId, setselectedChefID] = useState<string | null>(null)
  const [bookingID,setBookingID] = useState<string | null>()
  const [bookingdetails,setBookingDetails] = useState({
    requirement:"",
    meals:[],
    startDate:"",
    startTime:"",
    additionalServices:[],
    people:"",
    baseFare:"",
  })
  const [showModal, setShowModal] = useState(false);

  const token = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("id");

  const navigate = useNavigate();

  const openModal = () => {
    setShowModal(true);
  };
  
  const closeModal = () => {
    setShowModal(false);
  };
  

  const handleBookingEdit = async() => {
    const bookingEdit = {
      ...bookingdetails
    }
    console.log(bookingEdit)
    console.log("------------------",bookingdetails)

    try {
      const response = await fetch(`${process.env.React_App_Specific_Booking_View_Table_URL}${bookingID}`,{
        method: "PATCH",
        body:JSON.stringify(bookingEdit),
        headers:{
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      })

      const data = await response.json()
      console.log("===================",data)
      closeModal();
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getUserbookings = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(
          `${process.env.React_App_Specific_Booking_View_Table_URL}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer${token}`,
              userId: `${userId}`,
              offset:`10`,
              limit:`11`,
              search:`10`,
              filter:`10`,
            },
          }
        );
        await apiResponse(response.status)

        if(response.status === 401){
          navigate("/login")
        }
        else if (response.status === 200) {
          const data = await response.json();
          const mybooking = data?.data?.bookings || [];
          setUserbookings(mybooking);
          setFilteredBookings(mybooking);
          setIsLoading(false);

        } else {
          console.log("check status code")
          setIsLoading(false)
        }
      } catch (error) {
        console.log("API is not able to fetch", error);
        setIsLoading(false)
      }
    };
    getUserbookings();
  }, [token, userId,navigate]);

  const handleSpecificBooking = async (bookingId: string) => {
    try {
      const response = await fetch(
        `${process.env.React_App_Specific_Booking_View_Table_URL}${bookingId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer${token}`,
          },
        }
      );

      const data = (await response.json())?.data;
      <Bookingdetails />;
      navigate("/bookingdetails", { state: { bookingview: data } });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = (filtered: string) => {
    let filteredBookings: mybooking[] = [];
    if (filtered === "complete") {
      filteredBookings = userbookings.filter((booking) => booking.status === 2);
    } else if (filtered === "pending") {
      filteredBookings = userbookings.filter((booking) => booking.status === 3);
    } else if (filtered === "cancel") {
      filteredBookings = userbookings.filter((booking) => booking.status === 0);
    } else {
      filteredBookings = userbookings;
    }
    setFilteredBookings(filteredBookings);
  };


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearch(query);
    if (query) {
      const filtered = userbookings.filter(
        (booking) =>
          booking.firstName?.toLowerCase().includes(query) ||
          booking.lastName?.toLowerCase().includes(query) ||
          booking.id?.toLowerCase().includes(query) ||
          booking.requirement?.toLowerCase().includes(query) ||
          booking.city?.toLowerCase().includes(query)
      );
      setFilteredBookings(filtered);
    } else {
      setFilteredBookings(userbookings);
    }
  };

  const handleReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log("revice");
    setNote(e.target.value);
  };

  const handleBookingCancel = async (bookingId: string, requirement: string, additionalServices: any, baseFare: string, meals: string) => {

    const bookingData = {
      requirement: requirement,
      additionalServices: additionalServices,
      baseFare: baseFare,
      meals: meals,
      status: "0"
    }


    const response = await fetch(`${process.env.React_App_Specific_Booking_View_Table_URL}${bookingId}`,
      {
        method: "PATCH",
        body: JSON.stringify(bookingData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    )
    
    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
    }else{
      console.log("Check Status code ")
    }
  }

  const handleSubmit = async () => {
    try {
      const feedback = {
        user: userId,
        description: note,
        rating: rating?.toString(),
        booking: selectedBookingId,
        chef: selectedChefId,
      }
      
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_Rating_URL}`,
        {
          method: "POST",
          body: JSON.stringify(feedback),
          headers: {
            "Contact-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log("data", data);
      } else {
        alert("please cheack your details");
      }
      
    } catch (error) {
      console.log(error)
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === "additionalServices") {
      const services:any = value.split(",");
      setBookingDetails((prevState) => ({
        ...prevState,
        [name]: services,
      }));
    } else if (name === "meals") {
      const meals:any = value.split(",");
      setBookingDetails((prevState) => ({
        ...prevState,
        [name]: meals,
      }));
    } else {
      setBookingDetails({ ...bookingdetails, [name]: value });
    }
  };
  

  return (
    <>
      <div className="user-bookings pt-4 pb-5">
        <div className="container-fluid">
          <div className="booking-table-head">
            <div className="d-flex">
              <Link to="/" className="back-button">
                <i className="fa fa-angle-left me-2"></i>
              </Link>
              <div className="text-center">
                <h2 className="spacing-head">My Bookings</h2>
              </div>
            </div>
            {/* <div className="table-heading">
              <h2>My Bookings</h2>
            </div> */}
            <div className="status-symbol">
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%208.svg" alt="" />
              <span className="mx-2 status-text">Complete</span>
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%2011.svg" alt="" />
              <span className="mx-2 status-text">Placed</span>
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/Ellipse%2012.svg" alt="inprogress" />
              <span className="mx-2 status-text">inprogres</span>
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%209.svg" alt="" />
              <span className="mx-2 status-text">pending</span>
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%2010.svg" alt="cancle" />
              <span className="mx-2 status-text">Cancle</span>
            </div>
            <div className="table-filter">
              <div className="dropdown">
                <button
                  className="rounded border filter-button dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-filter"></i> Filter
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleFilter("complete")}
                    >
                      complete
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleFilter("pending")}
                    >
                      Inprogres
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleFilter("cancel")}
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="table-search">
              <input
                className="form-control bg-body-secondary w-100 fw-medium p-1 ps-3 "
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped text-start border border-2">
              <thead className="">
                <tr className="">
                  <th scope="col">Booking ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Requirement</th>
                  <th scope="col">Chef</th>
                  <th scope="col">Create Booking</th>
                  <th scope="col">Booking Date</th>
                  <th scope="col" className="text-center">
                    Status
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <>
                    <div className="loader-center">
                      <Loader />
                    </div>
                  </>
                ) : (
                  <>
                    {filteredBookings.map((e: any, index: any) => (
                      <tr>
                        <td key={index}>{e.id}</td>
                        <td>
                          {e.billingAddress.firstName} {e.billingAddress.lastName}
                        </td>
                        <td>{e.requirement}</td>
                        <td>{e.chef.firstName} {e.chef.lastName}</td>
                        <td>{e.createdAt}</td>
                        <td>{e.startDate}</td>
                        <td className="text-center">
                          {e.status === 0 ? (
                            <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%2010.svg" alt="Cancel" />
                          ) : e.status === 1 ? (
                            <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%208.svg" alt="Complete" />
                          ) : e.status === 2 ? (
                            <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/Ellipse%2012.svg" alt="inprogres" />
                          ) : e.status === 3 ? (
                            <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%2011.svg" alt="booking placed" />
                          ) : e.status === 4 ? (
                            <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%209.svg" alt="pending" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <button
                            className="bg-body-emphasis rounded btn-secondary filter-button border-0 mt-1 dropdown"
                            type="button"
                            id="dropdownMenu1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa fa-ellipsis-v"></i>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleSpecificBooking(e.id)}
                              >
                                Details
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {
                                  setSelectedBookingId(e.id);
                                  setselectedChefID(e.chef);
                                }}
                              >
                                Give rating
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleBookingCancel(e.id, e.requirement, e.additionalServices, e.baseFare, e.meals)}
                              >
                                Cancel
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                id="modal"
                                data-bs-target="#exampleModal1"
                                onClick={() => {setBookingID(e.id);
                                  setBookingDetails({  // Populate the edit form with current booking details
                                    requirement: e.requirement,
                                    meals: e.meals,
                                    startDate: e.startDate,
                                    startTime: e.startTime,
                                    additionalServices: e.additionalServices,
                                    people: e.people,
                                    baseFare:e.baseFare,
                                  });
                                  openModal();
                                }}
                              >
                                Edit
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* review model */}
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <label className="form-label"> Rate Us</label>
              <div>
                {[...Array(5)].map((_, index) => {
                  const currentRating: any = index + 1;
                  return (
                    <>
                      <label key={index} className="text-center">
                        <input
                          type="radio"
                          name="rating"
                          className="rating-input"
                          value={currentRating}
                          style={{ display: "none" }}
                          onClick={() => setRating(currentRating)}
                        />
                        <FaStar
                          className="star"
                          size={25}
                          color={
                            currentRating <= (hover ?? rating ?? 0)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          onMouseEnter={() => setHover(currentRating)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    </>
                  );
                })}
              </div>
              {/* <p>Your rating is {rating}</p> */}
              <label>Review Our Service</label>
              <div className="">
                <textarea
                  className="user-rating w-100"
                  value={note}
                  onChange={handleReview}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn border"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn border"
                onClick={handleSubmit}
              >
                Rate
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* edit modal */}

      <div className="modal fade" id="exampleModal1" aria-labelledby="exampleModalLabel1" aria-hidden="true" style={{ display: showModal ? 'none' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit Booking</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Requirement</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Requirement" name="requirement" value={bookingdetails.requirement} onChange={handleChange} />
              </div>
              <div className="mb-3">firstName
                <label htmlFor="exampleFormControlInput1" className="form-label">Meals</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Meals" name="meals" value={bookingdetails.meals} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Additional</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Additional Service" name="additionalServices" value={bookingdetails.additionalServices} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">No Of People</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Number Of People" name="people" value={bookingdetails.people} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Start Date</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Start Date" name="startDate" value={bookingdetails.startDate} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Start Time</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Start Time" name="startTime" value={bookingdetails.startTime} onChange={handleChange} />
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn border" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
              <button type="button" className="btn border" onClick={handleBookingEdit} >Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mybookings;

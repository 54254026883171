import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";

interface Review {
  id: string;
  user: string;
  chef: string;
  rating: number;
  description: string;
  createdAt: string;
}

const GetUserRating = () => {
  const [review, setReview] = useState<Review[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem("accessToken");
  const userObj: any = localStorage.getItem("user");
  const user = JSON.parse(userObj);
  const userID = user.Id;
  const userRole = user.role;

  useEffect(() => {
    const review = async () => {
      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_Rating_URL}${userID}?role=${userRole}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer${token}`,
            },
          }
        );
        const data = await response.json();
        const review = data?.data?.booking;
        setReview(review);
        setIsLoading(false);
        console.log(review);
      } catch (error) {
        console.log(error);
      }
    };
    review();
  }, [token,userID,userRole]);

  return (
    <>
      {isLoading ? (
        <>
          <div className="loder-center">
            <Loader />
          </div>
        </>
      ) : (
        <>
          <div className="row">
            {!review ? (
              <>
                <h1 className="text-center mt-5">Not Review Found</h1>
              </>
            ) : (
              <>
                {review.map((e: any, index: any) => (
                  <div className="col" key={index}>
                    <div className="feedback-card">
                      <div className="feedback-card-iteam">
                        <p>{e.description}</p>
                      </div>
                      <hr className="w-100 " style={{ margin: "5px" }} />
                      <div className="feedback-bottom d-flex justify-content-between">
                        <div className="d-flex">
                          <p className="mx-1 mt-1">{e.user}</p>
                        </div>
                        <div className="text-warning">
                          {[...Array(parseInt(e.rating))].map((i) => (
                            <i className="fa fa-star" key={i}></i>
                          ))}
                          {[...Array(5 - parseInt(e.rating))].map((i) => (
                            <i className="fa fa-star-o" key={i}></i>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
      
    </>
  );
};

export default GetUserRating;

// import { Link, useLocation } from "react-router-dom";
// import axios from "axios";
// import "./payment-gatway.css";

// const MERCHANT_ID = "M22F1UQL3OQ50";
// const SALT_KEY = "6f6236bd-6d57-4ea8-9f55-3bca14fa3f6c"; // Move to ENV
// const saltIndex = 1;

// // UTF-8 Safe Base64 Encoding
// const toBase64 = (obj: any) => {
//   return btoa(unescape(encodeURIComponent(JSON.stringify(obj))));
// };

// // Generate X-VERIFY Header
// const generateXVerifyHeader = async (
//   payload: any,
//   saltKey: string,
//   saltIndex: number
// ) => {
//   const base64Payload = toBase64(payload);
//   const stringToHash = base64Payload + "/pg/v1/pay" + saltKey;

//   // Compute SHA-256 hash
//   const encoder = new TextEncoder();
//   const data = encoder.encode(stringToHash);
//   const hashBuffer = await crypto.subtle.digest("SHA-256", data);
//   const hashHex = Array.from(new Uint8Array(hashBuffer))
//     .map((byte) => byte.toString(16).padStart(2, "0"))
//     .join("");

//   return {
//     xVerifyHeader: `${hashHex}###${saltIndex}`,
//     base64Payload,
//   };
// };

// export const Payment_gatway = () => {
//   const location = useLocation();
//   const fare: number = location.state?.bookingData?.booking?.finalFare || 0;
//   const phone = localStorage.getItem("phone");
//   // Calculate 25% of total fare
//   const amountToPay = Math.round(fare * 0.25); // Convert to paise

//   // Generate Unique Transaction ID
//   const merchantTransactionId = crypto.randomUUID();

//   // **Generate Merchant User ID**
//   // Agar user login hai to uska userId lo, nahi to random UUID use karo
//   const merchantUserId =
//     location.state?.bookingData?.userId || crypto.randomUUID();

//   const MERCHANT_DATA = {
//     merchantId: MERCHANT_ID,
//     merchantTransactionId,
//     merchantUserId, // Unique user ID (DB se ya Random)
//     amount: amountToPay*100,
//     redirectUrl: "https://chef.dyo.world/payment",
//     callbackUrl: "https://transaction-api.dyo.world/v1.0/transactions/status/",
//     mobileNumber: phone,
//     paymentInstrument: {
//       type: "PAY_PAGE",
//     },
//   };

//   const handlePayClick = async () => {
//     try {
//       const { xVerifyHeader, base64Payload } = await generateXVerifyHeader(
//         MERCHANT_DATA,
//         SALT_KEY,
//         saltIndex
//       );

//       const options = {
//         method: "POST",
//         url: "https://api.phonepe.com/apis/hermes/pg/v1/pay", // Production URL
//         headers: {
//           accept: "application/json",
//           "Content-Type": "application/json",
//           "X-VERIFY": xVerifyHeader,
//         },
//         data: JSON.stringify({ request: base64Payload }),
//       };

//       const response = await axios.request(options);
//       console.log("Payment Response:", response.data);

//       if (
//         response.data.success &&
//         response.data.data.instrumentResponse.redirectInfo.url
//       ) {
//         window.location.href =
//           response.data.data.instrumentResponse.redirectInfo.url;
//       } else {
//         console.error("Payment failed:", response.data);
//         window.location.href = "https://chef.dyo.world/payment/failed";
//       }
//     } catch (error) {
//       console.error("Error in payment:", error);
//       window.location.href = "https://chef.dyo.world/payment/failed";
//     }
//   };

//   return (
//     <>
//       <div>
//         <Link
//           to="/journalbookingdetails"
//           className="back-button d-flex ms-3 mt-3 "
//         >
//           <i className="fa fa-angle-left me-3"></i>
//           <h5 className="m-0">Payment</h5>
//         </Link>
//         <div className="payment-gatway">
//           <div className="payment-iteams">
//             <h6>Pay Now</h6>
//             <p>Please pay 25% of the total amount to conform your booking </p>
//             <div className="payment-amount">
//               <i className="fa fa-rupee"></i>
//               <h2>{amountToPay}</h2>
//             </div>
//             <button className="pay-button" onClick={handlePayClick}>
//               Pay Now
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };


import { Link, useLocation } from "react-router-dom";
import "./payment-gatway.css";
import { useNavigate } from "react-router-dom";


export const Payment_gatway = () => {
  const location = useLocation();
  const fare: number = location.state?.bookingData?.booking?.finalFare || 0;
  const amountToPay = Math.round(fare * 0.25); // Convert to paise
  const navigate = useNavigate()
  const handlePayClick = () => {
    navigate('/payment')
  }

  return (
    <>
      <div>
        <Link
          to="/journalbookingdetails"
          className="back-button d-flex ms-3 mt-3 "
        >
          <i className="fa fa-angle-left me-3"></i>
          <h5 className="m-0">Payment</h5>
        </Link>
        <div className="payment-gatway">
          <div className="payment-iteams">
            <h6>Pay Now</h6>
            <p>Please pay 25% of the total amount to conform your booking </p>
            <div className="payment-amount">
              <i className="fa fa-rupee"></i>
              <h2>{amountToPay}</h2>
            </div>
            <button className="pay-button" onClick={handlePayClick}>
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

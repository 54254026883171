// import { useLocation } from "react-router";
import "./payment.css";
import { Link } from "react-router-dom";
// import { useLocation } from "react-router";

const Payment = () => {
  // const location = useLocation();

  // const bookingData = location.state?.bookingData;
  // const bookingDetails = bookingData.booking
  // console.log(bookingDetails);

  return (
    <>
      <div className="payment-section">
        <div className="payment">
          <div className="text-end">
            <Link to="/">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
              ></button>
            </Link>
          </div>
          <div className="payment-gateway ">
            <div className="payment-gateway-inner text-center">
              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fpayment.svg" alt="" className="payment-logo mt-3" />
              <h1 className="success-message">Your Booking is successful</h1>
              {/* <p>Booking ID:{bookingDetails.id}</p> */}
            </div>
          </div>
          <div className="text-center">
            <Link to='/bookingview'><button className="payment-button">View booking</button></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;

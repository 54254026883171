import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Coupon from "../../components/Coupon";

function Nav() {
  const navigation = useNavigate();

  useEffect(() => {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    const currentTime = new Date().getTime();
    
    if (tokenExpiration && currentTime >= parseInt(tokenExpiration)) {
     
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("tokenExpiration");
      navigation("/login");
    }
  }, []);

  const token = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");
  const Id = localStorage.getItem("id");
  console.log("local storage",token,role,Id)

  const handleLogout = async () => {
    try {
      console.log(`${process.env.React_App_DYO_CHEF_Logout}/${Id}?role=${role}`)
      const response = await fetch(`${process.env.React_App_DYO_CHEF_Logout}/${Id}?role=${role}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer${token}`,
          }
        }
      )
      console.log(response)
      if (response.status === 200) {
        localStorage.clear();
        navigation("/login");
      }else{
        navigation("/")
      }
    } catch (error) {
      console.log("oops")
    }
  }

  return (
    <>
      <Coupon />
      <header className="bg-black" >
        <nav className="navbar  navbar-expand-lg accordion py-0 ">
          <div className="container-fluid">
            <div className="mx-5 brand-logo">
              <Link className="navbar-brand d-flex" to="/">
                <img
                  src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/Mark%20Logo.svg"
                  className="img-fluid"
                  alt="logo"
                />
              </Link>
            </div>
            <button
              className="navbar-toggler mt-2 mb-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {role === "chef" && token ? (
                <>
                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link mx-3 text-light navbar-option"
                        to="/contact"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link mx-3 text-light navbar-option"
                        to="/partner/registration"
                      >
                        Chef? Join Us
                      </Link>
                    </li>
                  </ul>

                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <p className=" mx-5 pt-3 ms-auto navbar-option">
                        <i className="fa fa-phone mx-2 text-light"></i>+91 7999
                        760937
                      </p>
                    </li>

                    {localStorage.getItem("accessToken") && (
                      <li className="nav-item">
                        <div className="dropdown">
                          <button
                            className="btn me-5"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fprofile.svg"
                              className=" img-fluid mt-2"
                              alt="user profile"
                            />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/partner/profile"
                              >
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/partner/bookings"
                              >
                                My Bookings
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/partner/feedbacks"
                              >
                                Review
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Log-Out
                              </button>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )}
                  </ul>
                </>
              ) : role === "user" && token ? (
                <>
                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link active mx-3 text-light navbar-option"
                        aria-current="page"
                        to="/allservice"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link mx-3 text-light navbar-option"
                        to="/contact"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <p className=" mx-5 pt-3 ms-auto navbar-option">
                        <i className="fa fa-phone mx-2 text-light"></i>+91 7999
                        760937
                      </p>
                    </li>

                    {localStorage.getItem("accessToken") && (
                      <li className="nav-item">
                        <div className="dropdown">
                          <button
                            className="btn me-5"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fprofile.svg"
                              className=" img-fluid mt-2"
                              alt="user profile"
                            />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/userprofile">
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/bookingview">
                                My Bookings
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/review">
                                Review
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Log-Out
                              </button>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )}
                  </ul>
                </>
              ) : !token && !role ? (
                <>
                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link active mx-3 text-light navbar-option"
                        aria-current="page"
                        to="/allservice"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link mx-3 text-light navbar-option"
                        to="/contact"
                      >
                        Contact Us
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link mx-3 text-light navbar-option"
                        to="/partner/registration"
                      >
                        Chef ? Join Us
                      </Link>
                    </li>
                  </ul>

                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <p className=" mx-5 pt-3 ms-auto navbar-option">
                        <i className="fa fa-phone mx-2 text-light"></i>+91 7999
                        760937
                      </p>
                    </li>

                    {localStorage.getItem("accessToken") ? (
                      <li className="nav-item">
                        <div className="dropdown ">
                          <button
                            className="btn me-5"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fprofile.svg"
                              className=" img-fluid mt-2"
                              alt="user profile"
                            ></img>
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/userprofile">
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/bookingview">
                                My Bookings
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Log-Out
                              </button>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ) : (
                      <li className="nav-item pt-2">
                        <Link
                          to="/login"
                          style={{ color: "#fff", textDecoration: "none" }}
                        >
                          <button className=" p-1 dyo-nav-button btn-rounded">
                            Login
                          </button>
                        </Link>
                      </li>
                    )}
                  </ul>
                </>
              ) : (
                <>
                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link active mx-3 text-light navbar-option"
                        aria-current="page"
                        to="/allservice"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link mx-3 text-light navbar-option"
                        to="/contact"
                      >
                        Contact Us
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link mx-3 text-light navbar-option"
                        to="/partner/registration"
                      >
                        Chef ? Join Us
                      </Link>
                    </li>
                  </ul>

                  <ul className="navbar-nav mx-5 ms-auto">
                    <li className="nav-item">
                      <p className=" mx-5 pt-3 ms-auto navbar-option">
                        <i className="fa fa-phone mx-2 text-light"></i>+91 7999
                        760937
                      </p>
                    </li>

                    {localStorage.getItem("accessToken") ? (
                      <li className="nav-item">
                        <div className="dropdown ">
                          <button
                            className="btn me-5"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fprofile.svg"
                              className=" img-fluid mt-2"
                              alt="user profile"
                            ></img>
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/userprofile">
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/bookingview">
                                My Bookings
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Log-Out
                              </button>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ) : (
                      <li className="nav-item pt-2">
                        <Link
                          to="/login"
                          style={{ color: "#fff", textDecoration: "none" }}
                        >
                          <button className=" p-1 dyo-nav-button btn-rounded">
                            Login
                          </button>
                        </Link>
                      </li>
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Nav;

// {!token && <div>Please log in to access this feature.</div>}
//       {token &&!userRole && <div>Your

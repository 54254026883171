import PartnerSidebar from "../PartnerSidebar";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router";
import Bookingdetails from "../../Booking/BookingDetails";
import { FaStar } from "react-icons/fa";
import apiResponse from "../../../utils/Response";


interface mybooking {
  status: number;
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  city: string;
  region: string;
  startDate: string;
  requirement: string;

  // coupon: string;
}

const PartnerBookings = () => {
  const [partnerbookings, setPartnerbookings] = useState<mybooking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<mybooking[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [rating, setRating] = useState<string | null>(null);
  const [hover, setHover] = useState<string | null>(null);
  const [note, setNote] = useState<string>("");
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null);
  const [selectedChefId, setselectedChefID] = useState<string | null>(null)

  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken");
  const userData: any = localStorage.getItem("user");
  const userObject = JSON.parse(userData);
  const userId = userObject.id;
  console.log(userId);

  useEffect(() => {
    const getUserbookings = async () => {
      try {
        const response = await fetch(
          `${process.env.React_App_Specific_Booking_View_Table_URL}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer${token}`,
              userID: `${userId}`,
            },
          }
        );
        
        await apiResponse(response.status)
        if(response.status === 401){
          navigate("/partner/login")
        }else if(response.status === 200){
          const data = await response.json();
          const mybooking = data?.data?.bookings || [];
          console.log("my booking", mybooking);
          setPartnerbookings(mybooking);
          setFilteredBookings(mybooking);
          setIsLoading(false);
        }

      } catch (error) {
        console.log("check api conditions", error);
      }
    };
    getUserbookings();
  }, [token, userId]);

  const handleSpecificBooking = async (bookingId: string) => {
    try {
      const response = await fetch(
        `${process.env.React_App_Specific_Booking_View_Table_URL}${bookingId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer${token}`,
          },
        }
      );
      const data = (await response.json())?.data;
      <Bookingdetails />;
      console.log("specific booking", data);
      navigate("/bookingdetails", { state: { bookingview: data } });
    } catch (error) {
      console.log(error);
    }
  };

  const handleReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log("revice");
    setNote(e.target.value);
    console.log("(((((((((((((((((((", note);
    console.log("userID", userId);

  };

  const handleSubmit = async () => {

    try {
      const feedback = {
        chef: userId,
        description: note,
        rating: rating?.toString(),
        booking: selectedBookingId,
        user: selectedChefId,
      }

      const response = await fetch(
        `https://rating.thevinayaksharan.workers.dev/v1.0/ratings`,
        {
          method: "POST",
          body: JSON.stringify(feedback),
          headers: {
            "Contact-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log("data", data);
      } else {
        alert("please cheack your details");
      }

    } catch (error) {
      console.log(error)
    }
  };

  const handleFilter = (filtered: string) => {
    let filteredBookings: mybooking[] = [];
    if (filtered === "complete") {
      filteredBookings = partnerbookings.filter((booking) => booking.status === 2);
    } else if (filtered === "pending") {
      filteredBookings = partnerbookings.filter((booking) => booking.status === 3);
    } else if (filtered === "cancel") {
      filteredBookings = partnerbookings.filter((booking) => booking.status === 0);
    } else {
      filteredBookings = partnerbookings;
    }
    setFilteredBookings(filteredBookings);
    console.log("------------------------", filteredBookings);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearch(query);
    if (query) {
      const filtered = partnerbookings.filter(
        (booking) =>
          booking.firstName?.toLowerCase().includes(query) ||
          booking.lastName?.toLowerCase().includes(query) ||
          booking.id?.toLowerCase().includes(query) ||
          booking.requirement?.toLowerCase().includes(query) ||
          booking.city?.toLowerCase().includes(query)
      );
      setFilteredBookings(filtered);
    } else {
      setFilteredBookings(partnerbookings);
    }
  };

  return (
    <>
      <PartnerSidebar />
      <div className="seprator-50">
        <div className="pb-5">
          <div className="container-fluid">
            <div className="booking-table-head">
              <div className="table-heading">
                <h2>My Booking</h2>
              </div>
              <div className="status-symbol">
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%2010.svg" alt="cancle" />
                <span className="mx-2 status-text">Cancle</span>
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%208.svg" alt="" />
                <span className="mx-2 status-text">Complete</span>
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/Ellipse%2012.svg" alt="inprogress" />
                <span className="mx-2 status-text">inprogres</span>
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/Ellipse%2012.svg" alt="" />
                <span className="mx-2 status-text">Placed</span>
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%209.svg" alt="" />
                <span className="mx-2 status-text">pending</span>
              </div>
              <div className="table-filter">
                <div className="dropdown">
                  <button
                    className="rounded btn-secondary border filter-button dropdown-toggle  p-1 px-3  "
                    type="button"
                    id="dropdownMenu2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-filter"></i> Filter
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleFilter("complete")}
                      >
                        complete
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleFilter("pending")}
                      >
                        Inprogres
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleFilter("cancel")}
                      >
                        cancel
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="table-search">
                <input
                  className="form-control bg-body-secondary w-100 rounded-pill fw-medium p-1 ps-3 "
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div className="table-responsive" style={{ marginTop: "62px;" }}>
              <table className="table table-striped text-start border border-2">
                <thead className="">
                  <tr className="">
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      Booking
                    </th>
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      Booking Date
                    </th>
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      Customer Name
                    </th>
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      Requirement
                    </th>
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      Address
                    </th>
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      People
                    </th>
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      Amount
                    </th>
                    <th scope="col" style={{ padding: "15px 2px 22px 10px;" }}>
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <>
                      <div className="loader-center">
                        <Loader />
                      </div>
                    </>
                  ) : (
                    <>
                      {filteredBookings.map((e: any, index: any) => (
                        <tr>
                          <td
                            key={index}
                            style={{ padding: "15px 0px 22px 10px" }}
                          >
                            {e.id}
                          </td>
                          <td style={{ padding: "15px 2px 22px 10px" }}>
                            {e.startDate}
                          </td>
                          <td style={{ padding: "15px 2px 22px 10px" }}>
                            {e.user}
                          </td>
                          <td style={{ padding: "15px 2px 22px 10px" }}>
                            {e.requirement}
                          </td>
                          <td style={{ padding: "15px 2px 22px 10px" }}>
                            {e.address}
                          </td>
                          <td style={{ padding: "15px 2px 22px 10px" }}>
                            {e.headCount}
                          </td>
                          <td
                            style={{ padding: "15px 2px 22px 10px" }}
                            className="text-center"
                          > {e.finalFare}
                          </td>
                          <td style={{ padding: "15px 2px 22px 10px" }}>
                            {e.status === 0 ? (
                              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%2010.svg" alt="Cancel" />
                            ) : e.status === 1 ? (
                              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%208.svg" alt="Complete" />
                            ) : e.status === 2 ? (
                              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/Ellipse%2012.svg" alt="inprogrees" />
                            ) : e.status === 3 ? (
                              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/Ellipse%2012.svg" alt="booking placed" />
                            ) : e.status === 4 ? (
                              <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/DYO%20Chef%2FEllipse%209.svg" alt="pending" />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <button
                              className="bg-body-emphasis rounded btn-secondary filter-button border-0 mt-1"
                              type="button"
                              id="dropdownMenu2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu2"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleSpecificBooking(e.id)}
                                >
                                  Details
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={() => { setSelectedBookingId(e.id); setselectedChefID(e.user) }}
                                >
                                  Give rating
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* review model */}
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <label className="form-label"> Rate Us</label>
              <div>
                {[...Array(5)].map((_, index) => {
                  const currentRating: any = index + 1;
                  return (
                    <>
                      <label key={index} className="text-center">
                        <input
                          type="radio"
                          name="rating"
                          className="rating-input"
                          value={currentRating}
                          style={{ display: "none" }}
                          onClick={() => setRating(currentRating)}
                        />
                        <FaStar
                          className="star"
                          size={25}
                          color={
                            currentRating <= (hover ?? rating ?? 0)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          onMouseEnter={() => setHover(currentRating)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    </>
                  );
                })}
              </div>
              {/* <p>Your rating is {rating}</p> */}
              <label>Review Our Service</label>
              <div className="">
                <textarea
                  className="user-rating w-100"
                  value={note}
                  onChange={handleReview}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn border"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn border"
                onClick={handleSubmit}
              >
                Rate
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerBookings;

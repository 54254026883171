import { useEffect, useState } from "react";
import chefcardinterface from "./chefcardinterface";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],

  appendDots: (dots: string | any[]) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ul> {dots.slice(0, 3)} </ul> {/* Display only the first three dots */}
    </div>
  ),
  customPaging: () => (
    <button>•</button> // Use a custom dot
  ),
};

const Chefcard = (props: chefcardinterface) => {
  const chefcard = props.chefcard;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an async data loading process
    const loadData = async () => {
      // Simulate a delay for loading data
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
    };

    loadData();
  }, []);

  return (
    <>
 
       <div className="seprator-100 our-team-section">
        <div className="container">
          <div className="section-title">
            <h2>Our Team</h2>
            <p>Savor Excellence: Meet Our Rated Chefs</p>
          </div>
          {isLoading ? (
            <>
              <div className="row">
                <div className="col-sm-3 mb-5">
                  <div style={{ margin: "0px 10px 0px 10px" }}>
                    <div className="our-inner-iteams mt-5">
                      <img
                        src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDYO-logo.svg"
                        className="card-img-top "
                        alt="chef"
                      />
                      <div className="content-chef-card">
                        <h5 className="card-title mt-3">DYO CHEF</h5>
                        <div className="d-flex pt-2">
                          <p className="rating-chef">
                            DYO CHEF
                            <i className="fa fa-star"></i>
                          </p>
                          <p className="mx-2">
                            DYO CHEF
                          </p>
                        </div>
                        <p className="cuisine-spece">North Indian</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-sm-3 mb-5">
                  <div style={{ margin: "0px 10px 0px 10px" }}>
                    <div className="our-inner-iteams mt-5">
                      <img
                        src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDYO-logo.svg"
                        className="card-img-top "
                        alt="chef"
                      />
                      <div className="content-chef-card">
                        <h5 className="card-title mt-3">Loading..</h5>
                        <div className="d-flex pt-2">
                          <p className="rating-chef">
                            loading...&nbsp;
                            <i className="fa fa-star"></i>
                          </p>
                          <p className="mx-2">
                            loading...
                          </p>
                        </div>
                        <p className="cuisine-spece">North Indian</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-sm-3 mb-5">
                  <div style={{ margin: "0px 10px 0px 10px" }}>
                    <div className="our-inner-iteams mt-5">
                      <img
                        src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDYO-logo.svg"
                        className="card-img-top "
                        alt="chef"
                      />
                      <div className="content-chef-card">
                        <h5 className="card-title mt-3">Loading..</h5>
                        <div className="d-flex pt-2">
                          <p className="rating-chef">
                            loading...&nbsp;
                            <i className="fa fa-star"></i>
                          </p>
                          <p className="mx-2">
                            loading...
                          </p>
                        </div>
                        <p className="cuisine-spece">North Indian</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 mb-5">
                  <div style={{ margin: "0px 10px 0px 10px" }}>
                    <div className="our-inner-iteams mt-5">
                      <img
                        src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDYO-logo.svg"
                        className="card-img-top "
                        alt="chef"
                      />
                      <div className="content-chef-card">
                        <h5 className="card-title mt-3">Loading..</h5>
                        <div className="d-flex pt-2">
                          <p className="rating-chef">
                            loading...&nbsp;
                            <i className="fa fa-star"></i>
                          </p>
                          <p className="mx-2">
                            loading...
                          </p>
                        </div>
                        <p className="cuisine-spece">North Indian</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
               
            </>
          ) : (
            
            <Slider {...settings}>
              
              {chefcard.map((e: any, index) => (
                <div className="col-sm-3 mb-5" key={index}>
                  <div style={{ margin: "0px 10px 10px 10px" }}>
                    {index % 2 === 0 ? (
                      <div className="our-inner-iteams mt-5">
                        <img
                          src={e.imageUrl}
                          className="card-img-top "
                          alt="chef"
                        />
                        <div className="content-chef-card">
                          <h5 className="card-title mt-3">{e.name}</h5>
                          <div className="d-flex pt-2">
                            <p className="rating-chef">
                              {e.rating}&nbsp;
                              <i className="fa fa-star"></i>
                            </p>
                            <p className="mx-2">
                              {e.experience}yr + Experience
                            </p>
                          </div>
                          <p className="cuisine-spece">North Indian</p>
                        </div>
                      </div>
                    ) : (
                      <div className="our-inner-iteams">
                        <img
                          src={e.imageUrl}
                          className="card-img-top "
                          alt="chef icon"
                        />
                        <div className="content-chef-card">
                          <h5 className="card-title mt-3">{e.name}</h5>
                          <div className="d-flex pt-2">
                            <p className="rating-chef">
                              {e.rating}&nbsp;
                              <i className="fa fa-star"></i>
                            </p>
                            <p className="mx-2">
                              {e.experience}yr + Experience
                            </p>
                          </div>
                          <p className="cuisine-spece">Continental</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              
            </Slider>
             
          )}
        </div>
      </div>
    </>
  );
};

export default Chefcard;

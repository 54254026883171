import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-4 contact-us text-white">
              <p><Link style={{ color: "#fff" }} target="_blank" to="/privacy-policy" >Privacy Policy</Link></p>
              <p><Link style={{ color: "#fff" }} target="_blank" to="/Refund_Policy" >Refund Policy</Link></p>
              <p><a style={{ color: "#fff" }} target="_blank" href="/Term_and_Condition" >Term and Condition</a></p>
              <p>(+91) 7999 760 937</p>
              <p>contact@dyo.world</p>
            </div>
            <div className="col-md-4 quick-link text-white">
              <h5>Quick Links</h5>
              <ul className="list-unstyled quick-link-inner">
                <li>
                  <a href="/" className="text-white text-decoration-none">HOME</a>
                </li>
                <li>
                  <a href="/" className="text-white text-decoration-none">PAGE</a>
                </li>
                <li>
                  <a href="/" className="text-white text-decoration-none">ABOUT US</a>
                </li>
                <li>
                  <a href="/" className="text-white text-decoration-none">BLOG</a>
                </li>
              </ul>
            </div>
            <div
              className="col-md-4 text-center text-white text-md-end contact-us footer-contact"
            >
              <h5 className="">Contact</h5>
              <a href="/" className="text-decoration-none">
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Ffacebook-FTR.svg" alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/dyochef" className="text-decoration-none">
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Ffoot-instagram.svg" alt="Instagram" />
              </a>
              <a href="/" className="text-decoration-none">
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Flinkedin.svg" alt="LinkedIn" />
              </a>
              <a href="/" className="text-decoration-none">
                <img src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Ffoot-twitter.svg" alt="Twitter" />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center bottom-bar border-top">
          <p>Copyright @ 2024-2030 DYO WORLD TECHNOLOGIES PRIVATE LIMITED - All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;

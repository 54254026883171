import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import apiResponse from "../../../utils/Response";
import { toast } from "react-toastify";

interface userdetail {
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  city: string;
  region: string;
  zip: string;
  countryCode: string;
  // coupon: string;
}

const GeneralBookingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken");
  const userId: any = localStorage.getItem("user");
  const userObject = userId ? JSON.parse(userId) : null;
  const userid = userObject ? userObject.id : null;
  console.log("USER ID ", userId);

  const [address, setAddress] = useState([]);
  const [getaddress, setGetaddress] = useState();
  const [addressid, setAddressid] = useState<string | null>(null);
  const [userdetail, setUserdetail] = useState<userdetail>({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    region: "",
    city: "",
    zip: "",
    countryCode: "+91",
    // coupon: "",
  });
  const [bookingAddress, setBookingAddress] = useState({});
  const [price, setPrice] = useState(null);
  const [coupon, setCoupon] = useState();
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const { bookingData, userData, fare } = location.state;

  // Use the data (for example, logging it)
  console.log("Booking Data:", bookingData);
  console.log("User Data:", userData);
  console.log("Fare:", fare);
  console.log(userData?.totalPrice);

  useEffect(() => {
    if (location.state) {
      setPrice(location.state.userData);
    }
  }, [location.state]);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setUserdetail({ ...userdetail, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    console.log("booking state", location.state.bookingData);
    console.log(userdetail);
  
    const id = localStorage.getItem("id");
    console.log("hello", userId, bookingAddress);
  
    if (Object.keys(bookingAddress).length === 0) {
      toast("Please select an address");
      return; 
    }
  
    const bookingdata = {
      ...location.state.bookingData,
      address: addressid,
      fareObject: price,
      user:id,
      billingAddress: bookingAddress,
      venue: bookingAddress,
      description: "",
    };
    console.log("0000000",bookingdata)
  
    if (!bookingdata.address || !bookingdata.user || !bookingdata.fareObject) {
      toast("Please fill in all the required fields!");
      return; 
    }
  
    console.log("booking data", bookingdata);
    try {
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_Create_Booking_URL}`,
        {
          method: "POST",
          body: JSON.stringify(bookingdata),
          headers: {
            "Content-Type": "application/json", // Correct header
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log("data", data);
        navigate("/Payment_gatway", { state: { bookingData: data?.data } });
      } else {
        toast("Please check your details");
      }
    } catch (error) {
      toast("Something went wrong!");
      console.log(error);
    }
  };
  

  const handleCouponChange = async (event: any) => {
    console.log("handle coupon", event.target.value);
    setCoupon(event.target.value);
  };

  const handleApplyCoupon = async () => {
    console.log("handle apply coupon");
    // Fetch call
    console.log(coupon);
    console.log(
      "booking data ---------------------->>>> ",
      location.state.userData
    );
    try {
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_Coupon_URL}${coupon}/verify?price=${location.state.userData}`,
        {
          method: "GET",
          headers: {
            "Contact-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = (await response.json())?.data;

        setPrice(data.discountedPrice);
        console.log(data.discountedPrice);
        toast("Coupon successfully applied.");
      } else {
        toast("Invalid coupon code.");
      }
    } catch (error) {
      toast("Invalid coupon code.");
      console.log("your coupon code is wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.React_App_DYO_CHEF_Save_Address_URL}${userId}/addresses`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await apiResponse(response.status);
        if (response.status === 401) {
          toast("Invalid user");
          navigate("/login");
        } else {
          const data = await response.json();
          console.log(data);
          const addresses = data.data.addresses;
          console.log("Addresses fetched: ", addresses);
          addresses.sort(function (a: any, b: any) {
            return a.updatedAt < b.updatedAt ? 1 : -1;
          });
          console.log("sort addresssssssss", addresses);
          setAddress(addresses);
          setAddressid(addresses.toString());
        }
      } catch (error) {
        toast("something went wrong");

        console.error("Fetch error: ", error);
      }
    };

    fetchData();
  }, [saveTrigger, setAddressid]);

  useEffect(() => {
    const fetchData = {
      ...userdetail,
    };
    console.log(fetchData);
  }, [userdetail]);

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(userdetail);
    try {
      const response = await fetch(
        `${process.env.React_App_DYO_CHEF_Save_Address_URL}${userid}/addresses`,
        {
          method: "POST",
          body: JSON.stringify(userdetail),
          headers: {
            "Contact-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setAddressid(data?.data?.address?.id);

        console.log("address id", addressid);
        toast("Address saved successfully.");
        setUserdetail({
          firstName: "",
          lastName: "",
          phone: "",
          address: "",
          region: "",
          city: "",
          zip: "",
          countryCode: "+91",
        });
        setIsAccordionOpen(false);
        setSaveTrigger((prev) => !prev);
      } else {
        toast("Address already exists.");
      }
    } catch (error) {
      toast("Something went wrong!");
      console.log("Api not working", error);
    }
  };

  const handleAddressSelect = (e: any) => {
    console.log("Save Address", e.target.value);
    const selectedValue = JSON.parse(e.target.value);
    setGetaddress(selectedValue.id);
    setAddressid(selectedValue.id);

    console.log("+++++++++++++++++++++", getaddress);
    console.log(selectedValue);
    setBookingAddress(selectedValue);
  };

  return (
    <>
      <div className="booking-getway-wrapper">
        <div className="booking-getway-inner">
          <div className="gap-4">
            <div className="booking-getway-inner-items">
              <div className="d-flex">
                <Link to="/booking" className="back-button">
                  <i className="fa fa-angle-left me-2"></i>
                </Link>
                <div className="text-center">
                  <h2 className="spacing-head">
                    Book Your <span> CHEF</span>
                  </h2>
                </div>
              </div>

              <div className="booking-scroller-iteams">
                <div className="accordion mt-3" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button-sym accordion-layout"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <span className="accordion-button-icon"></span>
                        Your Selections
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-details-body">
                        <ul>
                          {Object.entries(location.state.bookingData).map(
                            ([key, value]) => (
                              <li key={key} className="selection">
                                {key === "requirement" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FRequirement.svg"
                                    alt="requirement"
                                    className="me-1"
                                  />
                                ) : key === "meals" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FMeals.svg"
                                    alt="meals"
                                    className="me-1"
                                  />
                                ) : key === "additionalServices" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fadditional.svg"
                                    alt="additional"
                                    className="me-1"
                                  />
                                ) : key === "startTime" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FTime.svg"
                                    alt="startTime"
                                    className="me-1"
                                  />
                                ) : key === "startDate" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDate.svg"
                                    alt="startDate"
                                    className="me-1"
                                  />
                                ) : key === "headCount" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2Fpeople.svg"
                                    alt="people"
                                    className="me-1"
                                  />
                                ) : key === "endDate" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FDate.svg"
                                    alt="enddate"
                                    className="me-1"
                                  />
                                ) : key === "endTime" ? (
                                  <img
                                    src="https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/svg-images%2Fsvg-images%2FTime.svg"
                                    alt="endtime"
                                    className="me-1"
                                  />
                                ) : (
                                  ""
                                )}
                                <>
                                  {key} : {(value as string).toString()}
                                </>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="booking-add-gatway">
                  <div className="booking-inner-wraper">
                    <form className="booking-inner-iteam" onSubmit={handleSave}>
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item mb-2">
                          <h2 className="" id="headingTwo">
                            <button
                              className={`accordion-button-sym ${
                                isAccordionOpen ? "" : "collapsed"
                              } accordion-layout`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded={isAccordionOpen}
                              aria-controls="collapseTwo"
                              onClick={toggleAccordion}
                            >
                              <span className="accordion-button-icon"></span>
                              Add New Address
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className={`accordion-collapse collapse ${
                              isAccordionOpen ? "show" : ""
                            }`}
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="d-flex justify-content-between">
                                <div className="form-group mt-4">
                                  <input
                                    className="form-control booking-form-name-input"
                                    type="text"
                                    placeholder="Enter first name"
                                    name="firstName"
                                    value={userdetail.firstName}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="form-group mt-4">
                                  <input
                                    className="form-control booking-form-name-input "
                                    type="text"
                                    placeholder="Enter Last name"
                                    name="lastName"
                                    value={userdetail.lastName}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group mt-4">
                                <input
                                  className="form-control booking-form-input bg-white"
                                  type="text"
                                  placeholder="Enter your number"
                                  name="phone"
                                  value={userdetail.phone}
                                  onChange={handleChange}
                                  required
                                />
                              </div>

                              <div className="form-group mt-4">
                                <input
                                  className="form-control booking-form-input bg-white"
                                  type="text"
                                  placeholder="Enter your address"
                                  name="address"
                                  value={userdetail.address}
                                  onChange={handleChange}
                                  required
                                />
                              </div>

                              <div className="d-flex mt-4 justify-content-between">
                                <div className="form-group">
                                  <input
                                    className="form-control booking-form-single-input bg-white"
                                    type="text"
                                    placeholder="Select City"
                                    name="city"
                                    value={userdetail.city}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    className="form-control booking-form-single-input bg-white"
                                    type="text"
                                    placeholder="Select state"
                                    name="region"
                                    value={userdetail.region}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    className="form-control booking-form-single-input bg-white"
                                    type="text"
                                    placeholder="Enter PIN code"
                                    name="zip"
                                    value={userdetail.zip}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="text-end">
                                <button className="btn-dyo-save">Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <h5 className="booking-address ms-3">
                      {/* <span className="accordion-button-icon me-2">+</span> */}
                      Booking Address
                    </h5>
                    <div className="address-gatway">
                      {/* {
                       
                      } */}
                      {address.map((add: any, index: any) => (
                        <div className="d-flex address-layout" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="address"
                            value={JSON.stringify(add)}
                            id={`flexCheckDefault-${index}`}
                            onClick={handleAddressSelect}
                          />
                          <div className="mx-2">
                            <h6>
                              {add.firstName} {add.lastName}
                            </h6>
                            <p>
                              {add.address},{add.city},{add.region},{add.zip}
                            </p>
                            <p>{add.phone}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* <div className="coupon-wrapper">
                  <input
                    type="text"
                    placeholder="Coupon Code"
                    name="coupon"
                    value={coupon}
                    onChange={handleCouponChange}
                  />
                  <button
                    name="apply"
                    value={coupon}
                    onClick={handleApplyCoupon}
                  >
                    Apply
                  </button>
                </div> */}
              </div>
              <div className="coupon-wrapper">
                <input
                  type="text"
                  placeholder="Coupon Code"
                  name="coupon"
                  value={coupon}
                  onChange={handleCouponChange}
                />
                <button name="apply" value={coupon} onClick={handleApplyCoupon}>
                  Apply
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center booking-bottom-section">
              <h3 className="mt-2 d-flex align-items-center">
                <i className="fa fa-rupee"></i>
                {userData?.totalPrice}
                {/* <Popover selectedMeals={selectedMeals} placement="bottom" /> */}
              </h3>
              <button className="booking-button" onClick={handleSubmit}>
                Book
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralBookingDetails;
